import React from "react";
import {formatTextWithLineBreaks} from "../utils/helper";

export const Footer = (props) => {

  return (
      <div id="footer" className="d-flex flex-column">
          <div className="container-fluid">
              <div className="row justify-content-between align-items-center">
                  <div
                      className="d-flex justify-content-center  align-items-center">
                      <li>
                          {props.data && (
                              <>
                                  <img src={props.data.logo} className="logo" alt="Logo"/>
                              </>
                          )}
                      </li>
                      <li>
                          {props.data && (
                              <>
                                  {formatTextWithLineBreaks(props.data.text)}
                              </>
                          )}
                      </li>
                  </div>

              </div>
          </div>
      </div>
  );
};

import React, { useState } from 'react';

import {Dropdown} from "primereact/dropdown";

import 'primeflex/primeflex.css';



const LanguageSelector = ({ onLanguageChange, languages }) => {
    const [selectedLanguage, setSelectedLanguage] = useState(null);

    const selectedLanguageTemplate = (option, props) => {
        if (option) {
            return (
                <></>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const languageOptionTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <img
                    alt={option.name}
                    src={`https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png`}
                    className={`mr-2 flag flag-${option.code.toLowerCase()}`}
                    style={{ width: '18px' }}
                />
                <div>{option.name}</div>
            </div>
        );
    };

    const handleChange = (e) => {
        setSelectedLanguage(e.value);
        onLanguageChange(e.value); // Pasa el objeto de opción completo
    };

    return (
        <Dropdown
            value={selectedLanguage}
            onChange={handleChange}
            options={languages}
            optionLabel="name"
            placeholder=""
            valueTemplate={selectedLanguageTemplate}
            itemTemplate={languageOptionTemplate}
            className="selectLanguage"
        />
    );
};

export default LanguageSelector;

import React, {useState} from "react";
import {formatTextWithLineBreaks} from "../utils/helper";

export const Downloads = ({ languageData, downloadData }) => {

    const handleClick = (url) => {
        window.location.href = url;
    };

  return (
    <div id="downloads" className="text-center">
        <div className="container-fluid">
            <div className="play-now">
                {languageData ? (
                    <>
                        <img src={languageData.playnow} alt="Logo"/>
                    </>
                ) : null}
            </div>
            <div className="download-flex col-12  justify-content-center download-buttons-container">

                {downloadData.map((link) => (
                    <button
                        key={link.id}
                        className="download-button"
                        onClick={() => handleClick(link.url)}
                        style={{ cursor: 'pointer' }}
                    >
                        <i className={link.iconClass}></i>
                        <span>{languageData ? formatTextWithLineBreaks(languageData[link.id]) : 'loading...'}</span>
                    </button>
                ))}
            </div>
        </div>
    </div>
  );
};

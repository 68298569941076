import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Downloads } from "./components/downloads";
import { About } from "./components/about";
import { Trailer } from "./components/trailer";
import { Streamers } from "./components/streamers";
import { Support } from "./components/support";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import {Footer} from "./components/footer";
import LanguageSelector from "./components/LanguageSelector";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {

    const [fileURL, setfileURL] = useState("https://legend.mu/data/");
  const [landingPageData, setLandingPageData] = useState({});
  const [language, setLanguage] = useState('data_en.json'); // Idioma por defecto
    const [languages, setLanguages] = useState([]);
    const [downloadLinks, setDownloadLinks] = useState([]);
    const [supportData, setsupportData] = useState([]);
    const [streamersData, setstreamersData] = useState([]);

    useEffect(() => {
        fetchDataJson();
    }, []);

    useEffect(() => {
        if (language) {
            fetchLanguageData(language);
        }
    }, [language]);



    const fetchDataJson = async () => {
        const cacheKey = 'dataJson';
        const cacheExpiryKey = `${cacheKey}_expiry`;
        const cachedData = localStorage.getItem(cacheKey);
        const cacheExpiry = localStorage.getItem(cacheExpiryKey);

        // Verificar si el cache ha expirado o no existe
        if (cachedData && cacheExpiry && new Date().getTime() < cacheExpiry) {
            const data = JSON.parse(cachedData);
            setLanguages(data.languages);
            setDownloadLinks(data.downloads);

            setstreamersData(data.streamers);
            setsupportData(data.support);
        } else {
            try {
                const response = await fetch(fileURL + '/data.json');
                const data = await response.json();
                setLanguages(data.languages);
                setDownloadLinks(data.downloads);
                setsupportData(data.support);
                setstreamersData(data.streamers);

                // Almacenar en cache con un tiempo de expiración de 10 minutos
                localStorage.setItem(cacheKey, JSON.stringify(data));
                localStorage.setItem(cacheExpiryKey, new Date().getTime() + 10 * 60 * 1000);
            } catch (error) {
                console.error('Error loading data.json:', error);
            }
        }
    };

    const fetchLanguageData = async (file) => {
        const cacheKey = `languageData_${file}`;
        const cachedData = localStorage.getItem(cacheKey);
        const cacheExpiry = localStorage.getItem(`${cacheKey}_expiry`);

        // Verificar si el cache ha expirado o no existe
        if (cachedData && cacheExpiry && new Date().getTime() < cacheExpiry) {
            setLandingPageData(JSON.parse(cachedData));
        } else {
            try {
                const response = await fetch(`${fileURL}/${file}`);
                const data = await response.json();
                setLandingPageData(data);
                // Almacenar en cache con un tiempo de expiración de 10 minutos
                localStorage.setItem(cacheKey, JSON.stringify(data));
                localStorage.setItem(`${cacheKey}_expiry`, new Date().getTime() + 10 * 60 * 1000);
            } catch (error) {
                console.error('Error loading language data:', error);
            }
        }
    };

    const handleLanguageChange = (event) => {
        setLanguage(event.file);
    };

  return (
    <div>
        <Navigation data={landingPageData.Menu} >
            <LanguageSelector onLanguageChange={handleLanguageChange} languages={languages}/>
        </Navigation>
      <Header data={landingPageData.Header} />
        <Downloads
            languageData={landingPageData.Downloads}
            downloadData={downloadLinks}
        />
      <About data={landingPageData.About} />
      <Trailer data={landingPageData.Trailer} />
        <Streamers
            languageData={landingPageData.Streamers}
            streamerData={streamersData}
        />
        <Support
            languageData={landingPageData.Support}
            supportData={supportData}
        />
        <Footer data={landingPageData.Footer} />
    </div>
  );
};

export default App;

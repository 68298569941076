import React from "react";
import {formatTextWithLineBreaks} from "../utils/helper";

export const Support = ({ languageData, supportData }) => {

  return (
      <div id="support">
          <div className="container">
              <div className="row justify-content-center">
                  <div className="col-8">
                      <div className="about-text header-section text-center">
                          <h2>{languageData ? languageData.title : "loading..."}</h2>

                          <p>{languageData ? formatTextWithLineBreaks(languageData.text) : "loading..."}</p>
                          <a className="generic-button" href={supportData ? supportData.link : "#"}>{languageData ? formatTextWithLineBreaks(languageData.button_text) : "loading..."}</a>

                      </div>
                  </div>
              </div>
          </div>
      </div>
  );
};

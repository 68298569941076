import React from "react";
import { formatTextWithLineBreaks } from '../utils/helper';
export const Streamers = ({ languageData, streamerData }) => {
  return (
      <div id="streamers">
          <div className="container">
              <div className="row justify-content-center">
                  <div className="col-8">
                      <div className="header-section ">
                          <h2>{languageData ? languageData.title : "loading..."}</h2>
                      </div>
                      <div>
                          {languageData ? formatTextWithLineBreaks(languageData.text) : "loading..."}
                      </div>
                      <div className="margintop-20">

                          <a className="generic-button" target="_blank"
                             href={streamerData ? streamerData.link : "#"}>{languageData ? formatTextWithLineBreaks(languageData.button_text) : "loading..."}</a>

                      </div>
                  </div>
              </div>
          </div>
      </div>
  );
};
